import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import StepOneContainer from "../../../containers/sales-estimator/StepOneContainer";
import StepTwoContainer from "../../../containers/sales-estimator/StepTwoContainer";
import StepThreeContainer from "../../../containers/sales-estimator/StepThreeContainer";
import OfferPanelContainer from "../../../containers/sales-estimator/OfferPanelContainer";
import Continue from "./Continue";
import ControlContainer from "../../../containers/sales-estimator/ControlContainer";

const Estimator = ({ getIsSecondFormState, getFormBlock }) => {
  return (
    <section className="PgSE-Estimator">
      <div className="PgSE-title">
        <div className="container text-center">
          <p>Get Free Sales Info For Any Amazon Product</p>
          <p>
            Calculate your estimated average monthly sales with our free sales
            calculator tool
          </p>
        </div>
      </div>
      <div className="container">
        <div
          className="PgSE-Estimator__calculator"
          id="salesEstimator-behavior"
        >
          <div>
            <StepOneContainer />
            <StepTwoContainer />
            <StepThreeContainer />
          </div>
          <OfferPanelContainer />
          {getFormBlock ? (
            <Continue isNewUser={!getIsSecondFormState} />
          ) : (
            <ControlContainer />
          )}
        </div>
      </div>
    </section>
  );
};

Estimator.propTypes = {
  getIsSecondFormState: PropTypes.bool.isRequired,
  getFormBlock: PropTypes.bool.isRequired,
};

export default Estimator;
